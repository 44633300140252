<template>
  <div>
    <Dialog :provinecs="provinecs" />
    <v-card :loading="$store.state.loading" elevation="0" class="transparent">
      <v-card-title>
        <h3>المناطق</h3>
        <v-spacer></v-spacer>
        <v-btn @click="$store.commit('regions/toggleDialog')" color="primary">
          <v-icon>mdi-plus</v-icon>
          إضافة منطقة
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-chip-group v-model="options.ProvinceId">
              <v-chip label large filter :value="null">الكل</v-chip>
              <v-chip
                label
                large
                filter
                :value="provinec.id"
                v-for="provinec in provinecs"
                :key="provinec.id"
                >{{ provinec.name }}</v-chip
              >
            </v-chip-group>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :ripple="false"
              :items="regions"
              hide-default-footer
              :loading="$store.state.loading"
              no-data-text="لا يوجد"
              loading-text="جار التحميل..."
            >
              <template v-slot:item.created="{ item }">
                {{ $service.formatDate(new Date(item.created), false) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center">
                  <v-btn color="primary" @click="editRegion(item)" icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    color="error"
                    @click="deleteRegion(item)"
                    class="mx-2"
                    icon
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-center">
              <v-pagination
                v-model="options.PageIndex"
                :length="parseInt(total / options.PageSize) + 1"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Dialog from "./Dialog.vue";
export default {
  components: {
    Dialog,
  },
  data() {
    return {
      provinecs: [],
      regions: [],
      total: 1,
      headers: [
        { text: "الاسم", value: "name" },
        { text: "المحافظة", value: "provinec.name" },
        { text: "تاريخ الانشاء", value: "created" },
        { text: "الاجراءات", value: "actions" },
      ],
      options: {
        ProvinceId: "",
        PageIndex: 1,
        PageSize: 10,
      },
    };
  },
  created() {
    this.getRegions();
    this.getProvicecs();
  },
  methods: {
    editRegion(item) {
      let temp = item;
      temp.ProvinceId = this.provinecs.find(
        (provinec) => provinec.name == item.provinecName
      ).id;
      this.$store.commit("regions/updateItem", temp);

      this.$store.commit("regions/toggleEdit");

      this.$store.commit("regions/toggleDialog");
    },
    getProvicecs() {
      this.$http.get("/provinecs").then((response) => {
        this.provinecs = response.data.result;
      });
    },
    getRegions() {
      this.$http
        .get("/regions", { params: this.options })
        .then((response) => {
          this.regions = response.data.result.data;
          this.total = response.data.result.count;
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.getRegions();
      },
      deep: true,
    },
  },
};
</script>
